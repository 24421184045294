html {
  height: 100%;
}

body {
  height: 100%;
}

:root {
  --color-primary-100: #448aff;
  --color-transparent: rgba(255, 255, 255, 0);
}

.App {
  height: 100%;
  font-family: sans-serif;
  text-align: center;
}

.overflow-x{
  overflow-x: scroll;
}

.overflow-x::-webkit-scrollbar {
  display: none;
}

/*--- Input styles ---*/
input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}

/*--- styles for scrolling where de height is so large ---*/
.overflow-y::-webkit-scrollbar {
  width: 0.25rem;
  background-color: var(--color-transparent);
  padding: 2px;
}

.overflow-y::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-100);
  border-radius: 0.25rem;
}

/*--- triangle of ticket widget ---*/
.triangle-bottomleft {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 32px solid var(--color-black-20);
  border-right: 32px solid white;
}

.kyc-iframe-container {
  width: 100%;
  padding: 1rem;
}

.kyc-iframe-container iframe {
  border: 0;
}

.popup-content {
  background: transparent;
  border: 0;
}

.spinner {
  margin: 100px auto 0;
  width: 170px;
  text-align: center;
}

.spinner > div {
  margin: 0 10px;
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (min-width: 1156px) {
  /*--- for scrolling where de height is so large ---*/
  .overflow-y {
    overflow-y: scroll;
  }
}

@media (max-width: 580px) {
  .iframe-widget {
    width: 100%;
  }
}

.popup-overlay {
  overflow-y: auto;
}
